import { createSlice } from "@reduxjs/toolkit";

export const otherInfoSlice = createSlice({
  name: "Other",
  initialState: {
    date: '',
  },
  reducers: {
    SET_DATE: (state, action) => {
      state.date = action.payload
    },
  },
});

export const { SET_DATE } = otherInfoSlice.actions;

export default otherInfoSlice.reducer;
