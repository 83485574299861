import React from "react";
import "./footer1.scss";

const Footer1 = () => {
  return (
    <div className="gdwsudgsuigdsg33">
      <div className="main">

        <div className="container flex2">
        <div className="left">
          <h1 className="h4 text-center py-3 text-secondary"> Scholib. </h1>

          <p className="h5 f3 text-center mx-3 w600" style={{ color: "#38393B" }}>
            Scholib Tech Pvt. Ltd
          </p>
          <hr className="mx-5 my-4" />

          <section>
            <div className="contact"> 9806014021 </div>
            <div className="contact"> 9816929504 </div>
            <div className="contact"> contact@scholib.com </div>
            <div className="contact"> bisiness.ujjwal@gmail.com </div>
          </section>
        </div>



      <div className="right">
      <div className="mission">
        <p className="h6"> Our Mission </p>
      </div>

      <div className="message flex1">
        <p className="h5 mb-4"> Hii, from Scholib </p>

        <p className="h6 mb-3 text-secondary"> We want to transform the way our education system bringing new technology and we aims to help and support them grow through our softwares and our skills... </p>

      </div>
      </div>
      </div>



        <section>
          <hr className="mx-5 my-4" />
          <p className="h6 text-center"> Buddhashanti 2 Budhabare Jhapa </p>
          <p className="text-center copyright">
            
            &copy; Copyright by Scholib.com <br /> Scholib Tech Pvt. Ltd
          </p>
        </section>
      </div>
    </div>
  );
};

export default Footer1;
