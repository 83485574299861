import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'User',
  initialState: {
    loading: false,
    error: {},
    user: [],
  },
  reducers: {
    GET_USER: (state) => {
      state.loading = true;
    },
    GET_USER_SUCCESS: (state , payload) => {
      state.loading = false;
      state.user = payload;
    },
    GET_USER_FAIL: (state , payload) => {
      state.loading = false;
      state.error = payload;
    },
    LOGOUT_USER_SUCCESS: (state , payload) => {
      state.loading = false;
      state.user = [];
      state.error = {};
    },
    ERROR_REMOVE: (state) =>{
      state.loading = false;
      state.error.payload = null;
    }
  },
});

export const { GET_USER, GET_USER_FAIL ,GET_USER_SUCCESS,ERROR_REMOVE, LOGOUT_USER_SUCCESS } = userSlice.actions;

export default userSlice.reducer;
