import React, { useState } from "react";
import "./busStatus.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCircleCheck,
  faDollarSign,
  faLocationPin,
  faMap,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../basicComponents/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { SET_ALERT_GLOBAL } from "../../redux/AlertGlobalSlice";
import axios from "axios";

const BusStatus = ({
  _id,
  data,
  StudentCourseInfo,
  closeFunction = () => {},
}) => {
  const dispatch = useDispatch();

  const [busPlace, setBusPlace] = useState(null);
  const school = useSelector((state) => state.Home.school.payload);

  const schoolCode = school.schoolCode;

  const placeOptions = school.busFee
    .filter((ind) => ind.active === true)
    .map((ind) => ({
      label: ind.location,
      value: ind._id,
    }));

  placeOptions.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  function startBus() {
    if (!busPlace) {
      dispatch(
        SET_ALERT_GLOBAL({
          status: "PickUp point not selected",
          message: "Please select a pickup point",
        })
      );
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/adminStudent/${schoolCode}/student/${_id}/startBus`,
        {
          params: {
            classId: StudentCourseInfo.class,
            groupId: StudentCourseInfo.group,
            sectionId: StudentCourseInfo.section,
            location: busPlace,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch(SET_ALERT_GLOBAL(response.data));
          closeFunction();
        } else {
          dispatch(SET_ALERT_GLOBAL(response.data));
        }
      })
      .catch((error) => {
        const data = {
          message: error.message,
          status: "Cannot communicate with the server",
        };

        if (error.response) {
          dispatch(SET_ALERT_GLOBAL(error.response.data));
          return;
        }
        dispatch(SET_ALERT_GLOBAL(data));
      });
  }

  function endBus() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/adminStudent/${schoolCode}/student/${_id}/endBus`,
        {
          params: {
            classId: StudentCourseInfo.class,
            groupId: StudentCourseInfo.group,
            sectionId: StudentCourseInfo.section,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch(SET_ALERT_GLOBAL(response.data));
          closeFunction();
        } else {
          dispatch(SET_ALERT_GLOBAL(response.data));
        }
      })
      .catch((error) => {
        const data = {
          message: error.message,
          status: "Cannot communicate with the server",
        };

        if (error.response) {
          dispatch(SET_ALERT_GLOBAL(error.response.data));
          return;
        }
        dispatch(SET_ALERT_GLOBAL(data));
      });
  }

  function busPriceCalculator(
    date = "2080-02-01",
    dataArray,
    priceArray,
    date2 = "2080-01-01"
  ) {
    const getDaysDifference = (date2, date1) => {
      const oneDay = 24 * 60 * 60 * 1000;

      const firstDate = new Date(date1);
      const secondDate = new Date(date2);

      firstDate.setHours(0, 0, 0, 0);
      secondDate.setHours(0, 0, 0, 0);

      const timeDifference = firstDate.getTime() - secondDate.getTime();
      const daysDifference = Math.round(timeDifference / oneDay);

      return daysDifference;
    };

    let totalPrice = 0;

    dataArray.forEach((dataItem) => {
      const { place, start, end } = dataItem;
      const priceItem = priceArray.find((item) => item._id === place);

      if (priceItem) {
        const { amounts } = priceItem;
        let startDate = new Date(start);

        if (getDaysDifference(startDate, date2) > 0) {
          startDate = date2;
        }

        let endDate = end ? new Date(end) : new Date(date);

        amounts.forEach((each) => {
          let daysdif = getDaysDifference(each.date, endDate);

          if (daysdif > 0) {
            let interval = getDaysDifference(each.date, endDate);
            let interval2 = getDaysDifference(each.date, startDate);

            if (interval2 > 0) {
              interval = interval - interval2;
            }

            totalPrice += (each.amount / 30) * (interval + 1);
          }
        });
      }
    });

    return Math.ceil(totalPrice);
  }

  const [busFare, setBusFare] = useState(
    busPriceCalculator("2081-02-10", data.bus, school.busFee, "2080-01-01")
  );

  return (
    <div className="busmdaidna flex1">
      <div className="vmainqqw custom-scrollbar">
        {/* for closing the entire thing  */}
        <div className="closeContainer">
          <div className="close flex1" onClick={closeFunction}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        <div className="insidermain2323">
          <p className="h5 w500 text-center"> Bus Status </p>

          <div className="content">
            <div className="each flex4">
              <div className="left233z d-flex">
                <FontAwesomeIcon icon={faCircleCheck} />
                <p className="h7 text-secondary ms-2"> Current Status </p>
              </div>
              <div className="right233z">
                <p className="h7 text-secondary ms-2 w600">
                  {data.bus[0] && !data.bus[0].end ? "Active" : "Not Active"}
                </p>
              </div>
            </div>

            <div className="each flex4">
              <div className="left233z d-flex">
                <FontAwesomeIcon icon={faLocationPin} />
                <p className="h7 text-secondary ms-2"> Place </p>
              </div>
              <div className="right233z">
                <p className="h7 text-secondary ms-2 w600">
                  {data.bus[0] && !data.bus[0].end
                    ? placeOptions.find(
                        (ind) => ind.value === data.bus[0].place && ind.label
                      ).label
                    : "N/A"}
                </p>
              </div>
            </div>

            <div className="each flex4">
              <div className="left233z d-flex">
                <FontAwesomeIcon icon={faDollarSign} />
                <p className="h7 text-secondary ms-2"> Total Fare </p>
              </div>
              <div className="right233z">
                <p className="h7 text-secondary ms-2 w600"> Rs. {busFare} </p>
              </div>
            </div>

            <hr />

            <div className="each">
              <p className="h6 text-secondary w500"> Bus Service History</p>
            </div>

            <hr />

            {data.bus.length < 1 && (
              <p className="h6 w500 text-center text-secondary"> No data </p>
            )}
            {data.bus.length > 0 &&
              data.bus.map((ind) => {
                return (
                  <>
                    <div className="each flex4">
                      <div className="left233z d-flex">
                        <FontAwesomeIcon icon={faMap} />
                        <p className="h7 text-secondary ms-2"> Place </p>
                      </div>
                      <div className="right233z">
                        <p className="h7 text-secondary ms-2 w600">
                          {(() => {
                            const foundOption = placeOptions.find(
                              (amd) => amd.value === ind.place
                            );
                            return foundOption ? (
                              <span className="">{foundOption.label}</span>
                            ) : (
                              <span className="h7">Place Unknown</span>
                            );
                          })()}
                        </p>
                      </div>
                    </div>

                    <div className="each flex4">
                      <div className="left233z d-flex">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p className="h7 text-secondary ms-2"> Start Date </p>
                      </div>
                      <div className="right233z">
                        <p className="h7 text-secondary ms-2 w600">
                          {" "}
                          {ind.start.substring(0, 10)}{" "}
                        </p>
                      </div>
                    </div>
                    {ind.end && (
                      <div className="each flex4">
                        <div className="left233z d-flex">
                          <FontAwesomeIcon icon={faCalendar} />
                          <p className="h7 text-secondary ms-2"> End Date </p>
                        </div>
                        <div className="right233z">
                          <p className="h7 text-secondary ms-2 w600">
                            {" "}
                            {ind.end.substring(0, 10)}{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    {_id && !data.removedOn && <hr />}
                  </>
                );
              })}
          </div>

          {_id && !data.removedOn && (
            <section>
              {((data.bus[0] && data.bus[0].end) || data.bus.length === 0) && (
                <div className="location-Full flex4">
                  <p className="h6 w500" style={{ width: "120px" }}>
                    {" "}
                    Select Place :
                  </p>

                  <div
                    className="inttt"
                    style={{ width: "calc(100% - 120px)" }}
                  >
                    <Dropdown
                      options={placeOptions}
                      title={`Select One`}
                      onSelect={(a, b, c) => setBusPlace(c)}
                    />
                  </div>
                </div>
              )}

              {((data.bus[0] && data.bus[0].end) || data.bus.length === 0) && (
                <button
                  className="btn btn-primary my-3"
                  style={{ width: "100%", fontSize: "14px" }}
                  onClick={() => startBus()}
                >
                  Start Service
                </button>
              )}

              {data.bus[0] && !data.bus[0].end && (
                <button
                  className="btn btn-primary my-3"
                  style={{ width: "100%", fontSize: "14px" }}
                  onClick={() => endBus()}
                >
                  End Service
                </button>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusStatus;
