function isSameDate(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }



function parseDate(dateString) {
    try {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Validate the parsed date
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }

        // Extract year, month, and day
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // Months are 0-indexed, so add 1
        const day = date.getUTCDate();

        // Return the object
        return { year, month, day };
    } catch (error) {
        console.error(error.message);
        return null;
    }
}


module.exports = {isSameDate, parseDate}
